import React from 'react';

import { Container, Section } from '@solublestudio/soluto-design-system';

import PercentageColor from '@ds/components/shared/Color/Percentage';

function ColorsPercentageSection({ colors = [] }) {
    return (
        <Section className="pb-xxxl">
            <Container>
                <PercentageColor colors={colors} />
            </Container>
        </Section>
    );
}

export default ColorsPercentageSection;
