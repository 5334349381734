import React from 'react';
import {
    Container,
    Section,
    Row,
    Col,
    cls,
} from '@solublestudio/soluto-design-system';

import Heading, { HeadingProps } from '@ds/components/shared/Heading';

export interface TitleContentPagesSectionProps
    extends Pick<HeadingProps, 'title'> {}

const TitleContentPagesSection: React.FC<TitleContentPagesSectionProps> = ({
    title,
}) => {
    return (
        <Section className={cls('mb-xxxl', 'mt-huge')}>
            <Container>
                <Row>
                    <Col col={{ lg: 11, xxl: 8 }}>
                        <Heading component="h1" font="h500">
                            {title}
                        </Heading>
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

export default TitleContentPagesSection;
