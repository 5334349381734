import React from 'react';
import { cls } from '@solublestudio/soluto-design-system';

import { LegacyHeader as LegacyHeaderDS } from '@solublestudio/soluto-design-system';
import type { HeaderProps } from '@solublestudio/soluto-design-system';

import Logo from '@ds/img/logo.svg?url';
import LogoBlack from '@ds/img/logo-black.svg?url';

import Breadcrumb from '@ds/components/shared/Breadcrumb';
import type { BreadcrumbProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';
export interface SimpleHeaderProps
    extends Omit<HeaderProps, 'kind' | 'kindOnSticky'> {
    breadcrumb?: BreadcrumbProps['links'];
    logoDark?: boolean;
}
function SimpleHeader({
    breadcrumb = [],
    logoDark,
    ...props
}: SimpleHeaderProps) {
    return (
        <LegacyHeaderDS
            {...props}
            logo={{
                image: logoDark ? LogoBlack : Logo,
                href: '/',
                title: 'Cuerva',
            }}
            logoOnSticky={{
                image: Logo,
            }}
            kind="default"
            kindOnSticky="dark"
            navs={undefined}
            classes={{
                wrapper: styles.wrapper,
                scrollDown: styles.scrollDown,
            }}
            fullWidth={false}
            hideOnScroll={false}
            sticky="top"
            isMenuDesktop
            preContent={
                breadcrumb?.length > 0 ? (
                    <Breadcrumb
                        links={breadcrumb}
                        className={cls(styles.breadcrumb)}
                    />
                ) : null
            }
        />
    );
}

export default SimpleHeader;
