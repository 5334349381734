import React from 'react';

import styles from './styles.module.scss';

export interface AnchorProps {
    anchor: string;
}
const Anchor = ({ anchor }: AnchorProps) => {
    return <div className={styles.anchor} id={anchor} />;
};

export default Anchor;
