import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Heading from '@ds/components/shared/Heading';
import Picture, { PictureProps } from 'Picture';

import styles from './styles.module.scss';

export interface CardGraphicProps {
    image: PictureProps;
    text: string;
}

const CardGraphic = ({ image, text }: CardGraphicProps) => {
    return (
        <div
            className={cls(
                'd-flex flex-column justify-content-between bg-base-100',
                styles.cardGraphic,
            )}>
            <div className={cls('p-lg')}>
                <Heading
                    component="h4"
                    font="h300"
                    className={cls('text-base-1000', styles.text)}>
                    {text}
                </Heading>
            </div>
            <Picture
                image={image}
                alt={image?.alt ?? undefined}
                layout="full"
                objectFit="cover"
                classes={{
                    wrapper: cls(styles.img),
                }}
            />
        </div>
    );
};

export default CardGraphic;
