import React, { useCallback } from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';
import Button from '@ds/components/shared/Button';
import Picture, { PictureProps } from 'Picture';

import { withSolutoPrefix } from '@solublestudio/soluto-design-system/src/utils/link';

import type { LinkProps } from 'Link';

import styles from './styles.module.scss';

export interface CardResourceProps {
    image: PictureProps;
    description: string;
    title: string;
    horizontal?: boolean;
    link: LinkProps;
}

const CardResource = ({
    image,
    title,
    description,
    link,
    horizontal = false,
}: CardResourceProps) => {
    const handleClick = useCallback(
        (href: string, asDownload: boolean = false) => {
            if (asDownload) {
                window.open(withSolutoPrefix(href));
            }

            window.open(withSolutoPrefix(href), '_blank');
        },
        [],
    );

    return (
        <div
            onClick={() => handleClick(link.href, link?.download)}
            className={cls(
                styles.cardResource,
                horizontal && styles.horizontal,
            )}>
            <Picture
                image={image}
                alt={image?.alt ?? undefined}
                layout="full"
                objectFit="cover"
                classes={{
                    wrapper: cls(styles.img),
                }}
            />
            <div
                className={cls(
                    styles.cardResourceInfo,
                    'py-lg px-md',
                    'd-flex',
                    'flex-column',
                )}>
                <span>
                    <Heading
                        component="h4"
                        font="h100"
                        className={cls(styles.title)}>
                        {title}
                    </Heading>
                </span>
                {description && (
                    <Text
                        font="b300"
                        component="div"
                        className={cls('pt-xxs', 'mb-xxl', styles.description)}>
                        {description}
                    </Text>
                )}
                {link && (
                    <div className={cls('mt-auto')}>
                        <Button
                            {...link}
                            target="_blank"
                            className={styles.cta}
                            iconName="arrowRight"
                            variant="primary-small">
                            {link.label}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CardResource;
