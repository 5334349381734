import React from 'react';

import { Section, cls, VideoInline } from '@solublestudio/soluto-design-system';

import Picture, { PictureProps } from 'Picture';

import styles from './styles.module.scss';

export interface PictureSectionProps {
    size?: 'default' | 'panoramic';
    background:
        | PictureProps['image']
        | { file: { publicURL: string; extension: string } };
}

const PictureSection = ({
    background,
    size = 'default',
}: PictureSectionProps) => {
    return (
        <Section
            className={cls(
                'mb-huge',
                !background?.isImage && [
                    styles.videoWrapper,
                    styles[`${size}`],
                ],
            )}>
            {background &&
                (!background?.isImage ? (
                    <VideoInline
                        className={cls(styles.video)}
                        sources={[
                            {
                                src: background?.file?.publicURL,
                                type: background?.file?.extension,
                            },
                        ]}
                    />
                ) : background ? (
                    <Picture
                        {...background}
                        image={background}
                        alt={background?.alt}
                        layout="full"
                        objectFit="cover"
                        classes={{
                            wrapper: cls(styles.imgWrapper, styles[`${size}`]),
                        }}
                    />
                ) : null)}
        </Section>
    );
};

export default PictureSection;
