import React, { forwardRef } from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import { default as CRVLink, LinkProps as BaseLinkProps } from 'Link';

import styles from './styles.module.scss';

import { Font } from '@ds/types/font';

export interface LinkProps extends BaseLinkProps {
    font: Font;
    className?: string;
    children: React.ReactNode | undefined;
}

const Link: React.ForwardRefExoticComponent<LinkProps> = forwardRef(
    function Link({ className, font = 'h100', ...props }, ref) {
        return (
            <CRVLink
                ref={ref}
                classes={{
                    root: cls(styles['link'], styles[font], font, className),
                }}
                {...props}
            />
        );
    },
);

export default Link;
