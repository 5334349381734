import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import CardBgImageBase, {
    CardBgImageBaseProps,
} from '@ds/components/shared/Cards/BgImage';

import styles from './styles.module.scss';
import Topics, { TopicsProps } from '@ds/components/shared/Blog/Topics';

export interface CardBgImageLargeProps extends CardBgImageBaseProps {
    small?: boolean;
    topics?: TopicsProps['topics'];
}

const CardBgImageLarge = ({
    small = true,
    topics,
    ...props
}: CardBgImageLargeProps) => {
    return (
        <CardBgImageBase
            {...props}
            dataTheme="dark"
            classes={{
                wrapper: cls(styles.card, small && styles.cardSmall),
                title: cls(styles.title),
                category: cls(styles.category),
            }}
            titleFont="b300"
            categoryFont={small ? 'h300' : 'h400'}
            contentTitle={
                !!topics?.length && (
                    <div
                        className={cls(
                            'position-relative',
                            'mt-md',
                            styles.topics,
                        )}>
                        <Topics topics={topics} />
                    </div>
                )
            }>
            <div className={styles.topShadow} />
        </CardBgImageBase>
    );
};

export default CardBgImageLarge;
