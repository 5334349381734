import React, { useMemo } from 'react';
import { SimpleHeader } from '@solublestudio/cuerva-design-system';

import '@solublestudio/cuerva-design-system/src/scss/transitions.scss';

export default function Header({ navs, isDark, language, alternateLanguages }) {
    const nav = useMemo(
        () =>
            navs.map((item) => ({
                children: item.label,
                href: item.slug || '#',
                items: item.childLinks?.length
                    ? item.childLinks.map((subitem) => ({
                          children: subitem.label,
                          href: subitem.slug,
                      }))
                    : [],
            })),
        [navs],
    );

    return (
        <SimpleHeader
            nav={nav}
            logoLink={{
                href: '/',
                title: 'Greenmotril',
            }}
            language={language}
            alternateLanguages={alternateLanguages}
            title="Greenmotril"
        />
    );
}
