import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import CardBgImageBase, {
    CardBgImageBaseProps,
} from '@ds/components/shared/Cards/BgImage';
import Button from '@ds/components/shared/Button';

import type { CRVButtonProps } from '@ds/components/shared/Button';

import styles from './styles.module.scss';

export interface CardDownloadInsideProps extends CardBgImageBaseProps {
    button?: CRVButtonProps;
    featuredCard?: boolean;
}

const CardDownloadInside = ({
    button,
    featuredCard,
    ...props
}: CardDownloadInsideProps) => {
    return (
        <CardBgImageBase
            {...props}
            classes={{
                wrapper: cls(
                    styles.card,
                    featuredCard ? 'bg-primary' : 'bg-base-100',
                ),
                title: cls(styles.title),
            }}
            titleFont="b200"
            categoryFont="h200"
            dataTheme={featuredCard ? 'light' : 'dark'}>
            {button && (
                <Button
                    {...button}
                    className={cls(
                        'mt-auto',
                        'position-relative',
                        featuredCard && 'text-base-000',
                    )}
                    iconName="download"
                    variant="primary-small"
                    download
                />
            )}
        </CardBgImageBase>
    );
};

export default CardDownloadInside;
