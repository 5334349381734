import React from 'react';
import {
    Header as SolutoHeader,
    cls,
} from '@solublestudio/soluto-design-system';
import type { HeaderProps } from '@solublestudio/soluto-design-system';
import type { NavigationItemProps } from '@solublestudio/soluto-design-system';

import Logo from '~logo';
import LogoWhite from '~logo-white';

import Breadcrumb from '@ds/components/shared/Breadcrumb';
import SelectorLanguage, {
    AlternateLanguageProps,
} from '@ds/components/shared/SelectorLanguage';

import type { BreadcrumbProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

export interface SimpleHeaderProps
    extends Omit<HeaderProps, 'kind' | 'logo' | 'sticky' | 'megaMenu'> {
    breadcrumb?: BreadcrumbProps['links'];
    theme?: 'light' | 'dark';
    nav?: NavigationItemProps[];
    language: string;
    alternateLanguages: AlternateLanguageProps[];
    title?: string;
    logoLink?: {
        href: string;
        title: string;
    };
}

function SimpleHeader({
    breadcrumb = [],
    theme,
    nav,
    language,
    alternateLanguages,
    title,
    logoLink,
    ...props
}: SimpleHeaderProps) {
    return (
        <SolutoHeader
            {...props}
            kind="default"
            logo={{
                width: 100,
                height: 32,
                src: {
                    default: theme === 'dark' ? LogoWhite : Logo,
                    inversed: theme === 'dark' ? Logo : LogoWhite,
                },
                link: logoLink ?? {
                    href: '/',
                    title: 'Cuerva',
                },
            }}
            dropdownIcon="chevronDown"
            sticky="top"
            megaMenu
            classes={{
                root: cls(
                    styles.root,
                    !!breadcrumb?.length && styles.withBreadcrumb,
                ),
                navigationListItem: styles.listItem,
                navigationItem: styles.item,
                dropdownItem: styles.item,
                dropdownListItem: styles.dropdownListItem,
                footerMobile: styles.footerMobile,
                collapseContent: styles.collapseContent,
                megaMenuWrapper: styles.megaMenuWrapper,
            }}
            preNavContent={
                breadcrumb?.length > 0 ? (
                    <Breadcrumb
                        links={breadcrumb}
                        className={cls(styles.breadcrumb)}
                    />
                ) : null
            }
            postNavContent={
                <div
                    className={cls(
                        styles.selectorDesktop,
                        'd-none',
                        'd-lg-block',
                    )}>
                    <SelectorLanguage
                        alternateLanguages={alternateLanguages}
                        isDesktop={true}
                    />
                </div>
            }
            footerMobile={
                <SelectorLanguage alternateLanguages={alternateLanguages} />
            }
            closeOnClick
            {...(nav && {
                navs: [{ align: 'right', items: nav }],
            })}
        />
    );
}

export default SimpleHeader;
