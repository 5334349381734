import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import CardBgImageBase, {
    CardBgImageBaseProps,
} from '@ds/components/shared/Cards/BgImage';

import styles from './styles.module.scss';

export interface CardYearProps extends CardBgImageBaseProps {
    text: string;
    active: boolean;
}

const CardYear = ({ text, active, ...props }: CardYearProps) => {
    return (
        <CardBgImageBase
            {...props}
            category={text}
            classes={{
                wrapper: cls(styles.cardYear, active && styles.active),
                title: cls('p-lg mb-none text-base-000', styles.textContainer),
                category: cls('position-relative', styles.title),
            }}
            titleFont="b100"
            categoryFont="h100"></CardBgImageBase>
    );
};

export default CardYear;
