import React, { useMemo } from 'react';
import { useTranslate } from '@solublestudio/gatsby-theme-soluble-source';

import { Footer as FooterDS } from '@solublestudio/cuerva-design-system';

interface NavItem {
    childLinks: NavChildItem[];
}
interface NavChildItem extends NavItem {
    label: string;
    slug: string;
}
interface FooterProps {
    navs: NavItem[];
    [key: string]: any;
}

const Footer: React.FunctionComponent<FooterProps> = function Footer({
    navs,
    ...props
}) {
    const _ = useTranslate();
    const [links] = navs;
    const [footerLinks] = useMemo(
        () =>
            [links].map((nav) => {
                return (
                    nav?.childLinks.map((item) => {
                        return {
                            label: item.label,
                            href: item.slug,
                        };
                    }) ?? []
                );
            }),
        [links],
    );
    return (
        <FooterDS
            links={footerLinks}
            socialIcons={[]}
            copyright={_['footer.copyright']?.text}
            simpleFooter={true}
            {...props}
        />
    );
};

export default Footer;
