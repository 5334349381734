import React from 'react';

import {
    Section,
    Row,
    Col,
    cls,
    Container,
    animateScroll,
    useIsMobile,
} from '@solublestudio/soluto-design-system';

import Button, { CRVButtonProps } from '@ds/components/shared/Button';
import MediaBackgroundComponent, {
    MediaBackgroundComponentProps,
} from '@ds/components/shared/MediaBackgroundComponent';
import Text from '@ds/components/shared/Text';
import Heading from '@ds/components/shared/Heading';

import styles from './styles.module.scss';

export interface HeroSectionProps {
    title: string;
    background?: any;
    text?: string;
    ctas?: CRVButtonProps[];
    ctaDown?: CRVButtonProps;
    children?: React.ReactNode;
    align: 'end' | 'center';
    component?: React.ElementType;
    className?: string;
    withDelay?: boolean;
    bgCover?: MediaBackgroundComponentProps['bgCover'];
    theme?: string;
}

export function scrollToNextItem() {
    if (typeof window === 'undefined') {
        return;
    }

    const node = document.getElementById('hero-section');

    if (node) {
        const { bottom } = node.getBoundingClientRect();
        const offset = window.pageYOffset;

        animateScroll(bottom, offset, 0.2);
    }
}

const HeroSection = ({
    title,
    background,
    text,
    ctas,
    ctaDown,
    children,
    align = 'center',
    component = 'section',
    className,
    withDelay = true,
    bgCover = true,
    theme = 'dark',
}: HeroSectionProps) => {
    const isMobile = useIsMobile();

    return (
        <Section
            id="hero-section"
            data-theme={theme}
            className={cls(
                'pt-xxxl pb-xxxl',
                styles.heroSection,
                bgCover && styles.bgCover,
                className,
            )}
            component={component}>
            {background && (bgCover || !isMobile) && (
                <MediaBackgroundComponent
                    background={background}
                    withDelay={bgCover ? withDelay : false}
                    bgCover={bgCover}
                />
            )}
            <Container className={`${styles.heroContainer} d-flex flex-column`}>
                <Row className={cls(styles.heroRow)}>
                    <Col
                        col={{ lg: bgCover ? 7 : 5 }}
                        className={cls(
                            `d-flex flex-column justify-content-${align}`,
                            styles.heroContent,
                        )}>
                        <Heading
                            data-sal="slide-up"
                            data-sal-delay="200"
                            component="h1"
                            font={bgCover ? 'h500' : 'h400'}
                            className={cls(
                                styles.heroHeading,
                                bgCover && styles.heroHeadingBgCover,
                                'text-break',
                            )}>
                            {title}
                        </Heading>
                        {text && (
                            <Text
                                component="div"
                                font="h100"
                                data-sal="slide-up"
                                data-sal-delay="1200"
                                className={cls('mt-xxl')}>
                                {text}
                            </Text>
                        )}
                        {ctas && (
                            <div
                                className={cls(
                                    'd-flex flex-column flex-lg-row mt-xxl',
                                )}>
                                {ctas.map((cta, index) => {
                                    return (
                                        <Button
                                            {...cta}
                                            key={index}
                                            variant="primary-large"
                                            iconName={
                                                index === 0 ? 'arrowRight' : ''
                                            }
                                            className={cls(
                                                styles.cta,
                                                'text-base-1000',
                                            )}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </Col>
                    {children && (
                        <Col
                            data-sal="slide-up"
                            data-sal-delay="1200"
                            className="d-flex flex-column justify-content-center"
                            col={{ lg: 4 }}
                            offset={{ lg: 1 }}>
                            <div className={styles.heroChildren}>
                                {children}
                            </div>
                        </Col>
                    )}
                </Row>
                {ctaDown && (
                    <Row>
                        <Col
                            data-sal="slide-down"
                            data-sal-delay="2000"
                            data-sal-duration="300"
                            className="d-flex align-items-center mt-md">
                            <Button
                                {...ctaDown}
                                component="button"
                                variant="hero-cta"
                                iconName="arrowDownCircle"
                                className="text-base-1000"
                                onClick={scrollToNextItem}
                                children={ctaDown?.label && ctaDown.label}
                            />
                        </Col>
                    </Row>
                )}
            </Container>
        </Section>
    );
};

export default HeroSection;
