import React, { forwardRef } from 'react';

import { Text as TextDS, cls } from '@solublestudio/soluto-design-system';

import type { TextProps as BaseTextProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

import { Font } from '@ds/types/font';

export type TextProps = BaseTextProps & {
    font: Font;
};

const Text = forwardRef<HTMLDivElement, TextProps>(
    ({ className, font = 'h100', ...props }, ref) => {
        return (
            <TextDS
                ref={ref}
                className={cls(styles['text'], styles[font], font, className)}
                {...props}
            />
        );
    },
);

export default Text;
