import React, { useCallback, useMemo } from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import { getColorIsDarkByHex } from '@ds/utils/color';
import { getColorByImage } from '@ds/utils/image';
import { Font } from '@ds/types/font';

import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';

import Picture, { PictureProps } from 'Picture';

import Link, { LinkProps, navigate } from 'Link';
import { withSolutoPrefix } from '@solublestudio/soluto-design-system/src/utils/link';

export interface CardBgImageBaseProps {
    title: string;
    category: string;
    image?: PictureProps;
    titleFont: Font;
    categoryFont: Font;
    classes?: {
        wrapper?: string;
        title?: string;
        category?: string;
    };
    children?: React.ReactNode;
    contentTitle?: React.ReactNode;
    link?: LinkProps;
    dataTheme?: string;
}

const CardBgImageBase: React.FC<CardBgImageBaseProps> = ({
    title,
    category,
    image,
    titleFont,
    categoryFont,
    classes,
    children,
    contentTitle,
    link,
    dataTheme,
}) => {
    const thisDataTheme = useMemo(
        () =>
            dataTheme
                ? dataTheme
                : getColorIsDarkByHex(getColorByImage(image))
                ? 'light'
                : 'dark',
        [image, dataTheme],
    );

    const onClick = useCallback(
        (ev: React.MouseEvent<HTMLDivElement>) => {
            ev?.preventDefault();

            if (link?.target === '_blank') {
                window.open(link?.href, '_blank');
                return;
            }

            navigate(withSolutoPrefix(link.href));
        },
        [link],
    );

    return (
        <div
            data-theme={thisDataTheme}
            className={cls(
                'd-flex',
                'flex-column',
                'justify-content-between',
                'p-lg',
                'position-relative',
                classes && classes.wrapper,
            )}
            {...(!!link?.href && {
                onClick,
                role: 'button',
            })}>
            {image && (
                <Picture
                    image={image}
                    layout="background"
                    objectFit="cover"
                    alt={image?.alt ?? undefined}
                />
            )}
            <Text
                component="div"
                font={titleFont}
                className={cls(
                    'mb-xxs',
                    'position-relative',
                    classes && classes.title,
                )}>
                {category}
            </Text>
            <div>
                {link ? (
                    <Link {...link}>
                        <Heading
                            component="h4"
                            font={categoryFont}
                            className={cls(
                                'position-relative',
                                classes && classes.category,
                            )}>
                            {title}
                        </Heading>
                    </Link>
                ) : (
                    <Heading
                        component="h4"
                        font={categoryFont}
                        className={cls(
                            'position-relative',
                            classes && classes.category,
                        )}>
                        {title}
                    </Heading>
                )}
                {contentTitle}
            </div>
            {children}
        </div>
    );
};

export default CardBgImageBase;
