import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';

import Picture from 'Picture';

import styles from './styles.module.scss';

export interface ItemDescriptionProps {
    title?: string;
    image?: any;
    description: string;
    featured: boolean;
    reverse: boolean;
}

const ItemDescription: React.FC<ItemDescriptionProps> = ({
    title,
    image,
    description,
    featured = false,
    reverse = false,
}) => {
    return (
        <div
            data-sal="slide"
            data-sal-delay="200"
            className={cls(
                featured && styles['featured'],
                reverse && styles['reverse'],
            )}>
            {title && (
                <Heading component="h4" font={reverse ? 'h100' : 'h200'}>
                    {title}
                </Heading>
            )}
            {image && (
                <Picture
                    image={image}
                    alt={image?.alt ?? undefined}
                    classes={{
                        wrapper: cls(styles.wrapper),
                        picture: cls(styles.picture),
                    }}
                />
            )}
            <Text
                component="div"
                font={reverse ? 'b100' : 'b400'}
                className={cls(styles.description, !reverse && 'mt-md')}>
                {description}
            </Text>
        </div>
    );
};

export default ItemDescription;
