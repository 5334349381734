const solutoIcons = require('@solublestudio/soluto-design-system/icons.js');

const icons = {
    ...solutoIcons,
    arrowDown: require('./src/img/icons/arrow-down.svg'),
    arrowDownCircle: require('./src/img/icons/arrow-down-circle.svg'),
    arrowRight: require('./src/img/icons/arrow-right.svg'),
    asterisco: require('./src/img/icons/asterisco.svg'),
    checkCircle: require('./src/img/icons/check-circle.svg'),
    chevronCircleRight: require('./src/img/icons/chevron-circle-right.svg'),
    chevronDown: require('./src/img/icons/chevron-down.svg'),
    chevronLeft: require('./src/img/icons/chevron-left.svg'),
    chevronRight: require('./src/img/icons/chevron-right.svg'),
    chevronRightWhite: require('./src/img/icons/chevron-right_white.svg'),
    chevronUp: require('./src/img/icons/chevron-up.svg'),
    close: require('./src/img/icons/close.svg'),
    crossCircle: require('./src/img/icons/cross-circle.svg'),
    download: require('./src/img/icons/download.svg'),
    instagram: require('./src/img/icons/instagram.svg'),
    linkedin: require('./src/img/icons/linkedin.svg'),
    facebook: require('./src/img/icons/facebook.svg'),
    link: require('./src/img/icons/link.svg'),
    email: require('./src/img/icons/email.svg'),
    medium: require('./src/img/icons/medium.svg'),
    menu: require('./src/img/icons/menu.svg'),
    off: require('./src/img/icons/off.svg'),
    search: require('./src/img/icons/search.svg'),
    share: require('./src/img/icons/share.svg'),
    slack: require('./src/img/icons/slack.svg'),
    twitter: require('./src/img/icons/twitter.svg'),
    user: require('./src/img/icons/user.svg'),
    cuervaAsterisco: require('./src/img/icons/cuerva-asterisco.svg'),
    play: require('./src/img/icons/play.svg'),
};

module.exports = icons;
