import React from 'react';

import {
    SocialIcons as SocialIconsDS,
    cls,
} from '@solublestudio/soluto-design-system';

import type { SocialIconsProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

export interface SocialIconsDSProps extends SocialIconsProps {
    size?: SocialIconsProps['size'];
    gap?: SocialIconsProps['gap'];
    classes?: SocialIconsProps['classes'];
}

const SocialIcons = ({
    size = 24,
    gap = 'md',
    classes = {},
    ...props
}: SocialIconsDSProps) => {
    return (
        <SocialIconsDS
            {...props}
            size={size}
            gap={gap}
            iconsColor="currentColor"
            classes={{
                ...classes,
                link: cls(styles.link, classes?.link),
            }}
        />
    );
};

export default SocialIcons;
