import React, { forwardRef } from 'react';

import {
    Breadcrumb as CRVBreadcrumb,
    cls,
} from '@solublestudio/soluto-design-system';

import type { BreadcrumbProps as BreadcrumbPropsDS } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

interface BreadcrumbProps extends BreadcrumbPropsDS {
    className?: string;
}

const Breadcrumb = forwardRef<HTMLDivElement, BreadcrumbProps>(
    ({ className, ...props }, ref) => {
        return (
            <CRVBreadcrumb
                {...props}
                ref={ref}
                classes={{
                    wrapper: cls(styles['breadcrumb'], 'b100', className),
                }}
            />
        );
    },
);

export default Breadcrumb;
