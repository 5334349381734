import React, {
    useCallback,
    useRef,
    useState,
    useEffect,
    ReactNode,
} from 'react';

import {
    Section,
    Container,
    cls,
    animate,
    Icon,
} from '@solublestudio/soluto-design-system';

import SliderControls from '@ds/components/shared/Slider/Controls';
import Anchor from '@ds/components/shared/Anchor';

import styles from './styles.module.scss';

export interface CarouselBaseSectionProps {
    preContent?: React.ReactNode;
    postContent?: React.ReactNode;
    items: any[];
    classes?: {
        section?: string;
        wrapper?: string;
        item?: string;
    };
    showControls?: boolean;
    sliderCardsControls?: boolean;
    anchor?: string;
}

function getItemsPosition(dom: any, modifier = 0) {
    const elements = dom.childNodes;
    return [...elements].map((element) => {
        const position = element.getBoundingClientRect();
        return position.left + modifier;
    });
}

const CarouselBaseSection: React.FC<CarouselBaseSectionProps> = ({
    preContent,
    postContent,
    classes,
    items,
    anchor,
    showControls = false,
    sliderCardsControls = false,
}) => {
    const [leftPhantomPosition, setLeftPhantomPosition] = useState<number>(0);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const phantomRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!phantomRef?.current || (!showControls && !sliderCardsControls)) {
            return;
        }

        const setup = () => {
            const position = phantomRef?.current?.getBoundingClientRect();

            if (position) {
                setLeftPhantomPosition(position.left);
            }
        };

        setup();
        window.addEventListener('resize', setup);
        return () => {
            window.removeEventListener('resize', setup);
        };
    }, [phantomRef, setLeftPhantomPosition, showControls, sliderCardsControls]);

    const onClickNext = useCallback(() => {
        const itemsPosition = getItemsPosition(
            wrapperRef?.current,
            -leftPhantomPosition,
        );

        const nextItem = itemsPosition.find((position) => position > 5);

        if (nextItem) {
            const currentPosition = wrapperRef?.current?.scrollLeft || 0;
            const newPosition = nextItem + currentPosition;
            animate(
                wrapperRef.current as HTMLElement,
                'scrollLeft',
                0,
                currentPosition,
                newPosition,
                Math.abs(nextItem) * 0.5,
                true,
            );
        }
    }, [wrapperRef, leftPhantomPosition]);

    const onClickPrev = useCallback(() => {
        const itemsPosition = getItemsPosition(
            wrapperRef?.current,
            -leftPhantomPosition,
        );
        const prevItem = itemsPosition
            .reverse()
            .find((position) => position < -5);

        if (prevItem) {
            const currentPosition = wrapperRef?.current?.scrollLeft || 0;
            const newPosition = prevItem + currentPosition;

            animate(
                wrapperRef?.current as HTMLElement,
                'scrollLeft',
                0,
                currentPosition,
                newPosition,
                Math.abs(prevItem) * 0.5,
                true,
            );
        }
    }, [wrapperRef, leftPhantomPosition]);

    return (
        <Section className={cls(classes?.section)}>
            {!!anchor && <Anchor anchor={anchor} />}
            {!!preContent && (
                <Container className={styles.containerPrecontent}>
                    <div className={cls('d-block')} ref={phantomRef}>
                        {preContent}
                        {!!sliderCardsControls && (
                            <div
                                className={cls(
                                    styles.cardsControls,
                                    'd-flex',
                                    'align-items-center',
                                )}>
                                <button
                                    onClick={onClickPrev}
                                    className={cls(styles.button)}>
                                    <Icon
                                        name="chevronLeft"
                                        size={32}
                                        classes={{
                                            icon: cls(
                                                styles.buttonIcon,
                                                'mr-tiny',
                                            ),
                                        }}
                                    />
                                </button>
                                <button
                                    onClick={onClickNext}
                                    className={cls(styles.button)}>
                                    <Icon
                                        name="chevronRight"
                                        size={32}
                                        classes={{
                                            icon: cls('text-base-1000'),
                                        }}
                                    />
                                </button>
                            </div>
                        )}
                    </div>
                </Container>
            )}
            <div
                ref={wrapperRef}
                className={cls(styles.wrapper, classes?.wrapper)}
                data-sal="slide-left-children">
                {items?.length > 0 &&
                    items.map((item: ReactNode, i: number) => (
                        <div
                            className={cls(styles.item, classes?.item)}
                            key={`carousel-item-${i}`}
                            data-sal="slide"
                            data-sal-delay={i * 200}>
                            {item}
                        </div>
                    ))}
            </div>
            {!!showControls && (
                <div>
                    <Container>
                        <div className={cls('d-block')} ref={phantomRef}>
                            <SliderControls
                                className="mt-md"
                                onClickPrev={onClickPrev}
                                onClickNext={onClickNext}
                                size="small"
                            />
                        </div>
                    </Container>
                </div>
            )}
            {!!postContent && <Container>{postContent}</Container>}
        </Section>
    );
};

export default CarouselBaseSection;
