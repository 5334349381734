import React from 'react';

import {
    Icon,
    cls,
    SliderControlProps,
} from '@solublestudio/soluto-design-system';

import styles from './style.module.scss';

export interface CRVSliderControlsProps extends SliderControlProps {
    size: 'small' | 'large';
    iconPrev?: string;
    iconNext?: string;
    className?: string;
    light?: boolean;
}

const sizes = {
    small: '16',
    large: '28',
};

const SliderControls = function SliderControls({
    size = 'small',
    iconPrev = 'chevronLeft',
    iconNext = 'chevronRight',
    className,
    onClickPrev,
    onClickNext,
    isFirst,
    isLast,
    light,
}: CRVSliderControlsProps): JSX.Element {
    return (
        <div
            className={cls(
                styles.controls,
                'd-flex justify-content-center',
                className,
            )}>
            <button
                onClick={onClickPrev}
                className={cls(
                    styles.button,
                    'p-tiny',
                    !!light && 'bg-base-1000',
                )}
                disabled={isFirst}>
                <Icon
                    name={iconPrev}
                    classes={{ icon: cls(styles.icon) }}
                    size={sizes[size]}
                />
            </button>
            <button
                onClick={onClickNext}
                className={cls(
                    styles.button,
                    'p-tiny',
                    !!light && 'bg-base-1000',
                )}
                disabled={isLast}>
                <Icon
                    name={iconNext}
                    classes={{ icon: cls(styles.icon) }}
                    size={sizes[size]}
                />
            </button>
        </div>
    );
};

export default SliderControls;
