import React from 'react';
import {
    Container,
    Section,
    Row,
    Col,
    cls,
    HTMLComponent,
    Heading,
} from '@solublestudio/soluto-design-system';

import type { HTMLComponentProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

export interface HTMLSectionProps extends Pick<HTMLComponentProps, 'text'> {
    title: string;
    alignRight?: boolean;
}

const HTMLSection: React.FC<HTMLSectionProps> = ({
    title,
    text,
    alignRight,
}) => {
    return (
        <Section className={cls('pt-huge pb-xxl')}>
            <Container>
                <Row>
                    {title && (
                        <Col
                            offset={{ lg: alignRight ? 4 : 0 }}
                            col={{ lg: 8 }}>
                            <Heading component="h1" className="h500 mb-mega">
                                {title}
                            </Heading>
                        </Col>
                    )}
                    <Col offset={{ lg: alignRight ? 4 : 0 }} col={{ lg: 8 }}>
                        <HTMLComponent
                            text={text}
                            className={styles.htmlSection}
                        />
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

export default HTMLSection;
